import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, catchError, forkJoin, from, of } from 'rxjs';
import { CoucheInterface } from '@/interfaces/couches';
import { AddCoucheInterface } from '@/interfaces/addcouche';

@Injectable({
  providedIn: 'root'
})
export class CouchesService {
  constructor(public apiService: ApiService) {}

  public couches: BehaviorSubject<CoucheInterface> = new BehaviorSubject<CoucheInterface>({} as CoucheInterface);

  loadCouches(): Promise<{ error: boolean; msg?: string }> {
    return new Promise((resolve, reject) => {
      forkJoin([from(this.apiService.getRequest('/api/couches'))])
        .pipe(catchError(err => of({ error: true, msg: err })))
        .subscribe(results => {
          this.couches.next(results[0]);

          resolve({
            msg: 'Success',
            error: false
          });
        });
    });
  }

  addCouche(data: FormData, description: string): Promise<{ error: boolean; msg?: string; results?: any }> {
    return new Promise((resolve, reject) => {
      forkJoin([from(this.apiService.post_requete_file('/api/couches', data))])
        .pipe(catchError(err => of({ error: true, msg: err })))
        .subscribe(results => {
          resolve({
            results: results[0],
            msg: 'Success',
            error: false
          });
          reject({
            results: results,
            msg: 'Error',
            error: true
          });
        });
    });
  }

  updateCouche(id: number, data: FormData): Promise<{ error: boolean; msg?: string; results?: any }> {
    return new Promise((resolve, reject) => {
      forkJoin([from(this.apiService.post_requete_file('/api/couches/' + id, data))])
        .pipe(catchError(err => of({ error: true, msg: err })))
        .subscribe(results => {
          resolve({
            results: results[0],
            msg: 'Success',
            error: false
          });
        });
    });
  }

  deleteCouche(id: number): Promise<{ error: boolean; msg?: string; results?: any }> {
    return new Promise((resolve, reject) => {
      forkJoin([
        from(
          this.apiService.post_requete('/api/couches/' + id, {
            _method: 'delete'
          })
        )
      ])
        .pipe(catchError(err => of({ error: true, msg: err })))
        .subscribe(results => {
          resolve({
            results: results[0],
            msg: 'Success',
            error: false
          });
        });
    });
  }

  getCouches(): CoucheInterface {
    return this.couches.getValue();
  }

  addMeta(id_couche: number): Promise<{ error: boolean; msg?: string; results?: any }> {
    return new Promise((resolve, reject) => {
      forkJoin([
        from(
          this.apiService.post_requete('/api/metadatas', {
            couche_id: id_couche
          })
        )
      ])
        .pipe(catchError(err => of({ error: true, msg: err })))
        .subscribe(results => {
          resolve({
            results: results[0],
            msg: 'Success',
            error: false
          });
        });
    });
  }
}
