import { SousThematique } from '@/interfaces/sousthematiques';
import { Thematique } from '@/interfaces/thematiques';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SousthematiquesService } from '@services/sousthematiques.service';
import { ThematiquesService } from '@services/thematiques.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-create.sousthematique',
    templateUrl: './create.sousthematique.component.html',
    styleUrls: ['./create.sousthematique.component.scss']
})
export class CreateSousthematiqueComponent implements OnInit {
  ngOnInit(): void {
      this.thematiques = this.thematiquesService.getThematiques().data.thematiques
        this.sousthematiqueForm = new UntypedFormGroup({
            nom: new UntypedFormControl(null, Validators.required),
          nom_en: new UntypedFormControl(null, Validators.required),
          thematiques : new UntypedFormControl(null, Validators.required)
        });
    }

    constructor(
      public sousthematiquesService: SousthematiquesService,
      public thematiquesService:ThematiquesService,
        private toastr: ToastrService,
        private router: Router
    ) {}

    public sousthematiqueForm: UntypedFormGroup;

  public issousThematiqueLoading = false;
  
  thematiques:Thematique[]

    selectedFiles?: FileList;
    currentFile?: File;
    progress = 0;
    message = '';
    preview = 'assets/img/defaut.png';

    imageInfos?: Observable<any>;

    selectFile(event: any): void {
        this.message = '';
        this.preview = '';
        this.progress = 0;
        this.selectedFiles = event.target.files;

        if (this.selectedFiles) {
            const file: File | null = this.selectedFiles.item(0);

            if (file) {
                this.preview = '';
                this.currentFile = file;

                const reader = new FileReader();

                reader.onload = (e: any) => {
                    console.log(e.target.result);
                    this.preview = e.target.result;
                };

                reader.readAsDataURL(this.currentFile);
            }
        }
    }

  async addSousThematique() {
      console.log(this.sousthematiqueForm.value.thematiques);
        const file: File | null = this.selectedFiles.item(0);

        if (this.sousthematiqueForm.valid) {
            this.issousThematiqueLoading = true;
            const formData = new FormData();
            formData.append('image_src', file, file.name);
            formData.append('nom', this.sousthematiqueForm.value.nom);
            formData.append('nom_en', this.sousthematiqueForm.value.nom_en);
            formData.append(
                'thematique_id',
                this.sousthematiqueForm.value.thematiques
            );
            await this.sousthematiquesService
                .addSousThematique(formData)
                .then((response: { error: boolean; msg?: string; results?:any}) => {
                    if (!response.results.success) {
                      this.toastr.error('Echec de Création');
                    } else {
                      this.toastr.success('Création de la sous-thématique réussie');
                      this.router.navigate(['/sousthematiques']);
                    }
                });
            this.issousThematiqueLoading = false;
        } else {
            this.toastr.error('Formulaire non valide');
        }
    }
}
